<template>
  <div class="w-100 p-1" >
    <table id="registro" class="table" :style="'height :' + register_y + 'px; max-width : '+ table_x +'px'" v-if="structure.mesure_type > 1">
      <tr>
        <td :colspan="structure.v_divisions.lenght" style="border : 1px solid black; position: relative;">
          <div class="d-flex innerDiv1" style="height : 100% ; flex-direction : column; justify-content: space-evenly">
          </div>
        </td>
      </tr>
    </table>
    <table id="esquema" class="table table-bordered" :style="'height :' + table_y + 'px; max-width : '+ table_x +'px'">
      <tr>
        <td v-for="vdivision, index in structure.v_divisions" :key="index" :style="'width : ' + vdivision.percent + '%'">
          <div class="d-flex outerDiv1">
            <div class="innerDiv1"  v-for="hdivision, key in vdivision.h_divisions" :key="key" :style="'height : ' + hdivision.percent + '%'">
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import { BRow} from 'bootstrap-vue'
  export default {
    components: { BRow},    
     props: ['structure','dimensions'],
    data() {
      return {
        table_y : 0,
        table_x : 0,
        register_y : 0
      }
    },
  created() {
    if(parseFloat(this.structure.frame_width) > parseFloat(this.structure.frame_height)){
      this.table_x = 200
    }else {
      this.table_x = 50
    }
    for (let i = 0; i < this.structure.v_divisions.length; i++) {
      this.structure.v_divisions[i].percent = (this.structure.v_divisions[i].measure/ this.structure.frame_width) * 100
       for (let j = 0; j < this.structure.v_divisions[i].h_divisions.length; j++) {
        this.structure.v_divisions[i].h_divisions[j].percent = ( this.structure.v_divisions[i].h_divisions[j].measure  / this.structure.frame_height) * 100;
      }      
    }
  },     
  mounted () {
    let clientWidth = document.getElementById('esquema').clientWidth;
    this.table_y = (this.table_x * this.structure.frame_height) / this.structure.frame_width;
    this.register_y = (this.table_x * this.structure.register_measure) / this.structure.frame_width;    
  },   
  }
</script>
<style>
.innerDiv1{
  position: relative;
  border : 1px solid black;
  background-color : lightgray !important;
}
.outerDiv1{
  position: relative;
  height : 100% ;
  flex-direction : column;
  justify-content: space-evenly;
}
#esquema, #registro, #tap, #bot {
  width : 100%;
  margin : 0 auto;
}
#esquema td, #registro td {
  padding: 0;
}

</style>
